import $ from "jquery";
import '@yaireo/tagify/dist/tagify.css'; // Importer le CSS de Tagify
import '../styles/css/tagify.css'
import Tagify from '@yaireo/tagify';


export function tagify_input(){

    let i= $('input[name="tagify-input"]')
    let input=i.get(0)
    let modalElement=$('.jconfirm.jconfirm-open').get(0)


    let tagify=new Tagify(input, {
        whitelist: [],
        focusable: false,
        dropdown: {
            position: 'input',
            maxItems: Infinity,
            enabled: 1, // always opens dropdown when input gets focus
            closeOnSelect: false, // Garde le dropdown ouvert après sélection
            appendTarget:modalElement

        }
    })



    i.show()

    // Écoute de l'événement "input" pour effectuer une recherche
    tagify.on('input', function (e) {
        const searchQuery = e.detail.value; // Récupère la valeur saisie

        if (searchQuery.length > 0) {
            // Appeler le serveur pour récupérer les suggestions
            $.ajax({
                url: '/admin/tagify/tags/search', // URL qui traite la recherche
                method: 'POST',
                data: { query: searchQuery }, // Envoyer la lettre ou la chaîne en cours de saisie
                success: function (response) {
                    // Supposons que le serveur retourne un tableau JSON de tags
                    tagify.whitelist = response.tags; // Met à jour la whitelist avec les suggestions
                    tagify.dropdown.show.call(tagify, searchQuery); // Force l'affichage du dropdown
                },
                error: function () {
                    console.error('Erreur lors de la récupération des tags');
                },
            });
        }
    });

    tagify.on('add', function (event) {
        // Récupérer les données du tag
        let tagData = event.detail.data;

        // Modifier le tag pour supprimer tout après "/"
        if (tagData.value.includes('/')) {
            $.ajax({
                url: '/admin/tagify/check_tag_for_creation',
                data: {
                    tagNom: tagData.value
                },

                method: 'POST'
            }).done(function (response) {
                if (response['code'] === 200) {
                    tagData.value = response['tagNom'];
                    tagify.replaceTag(event.detail.tag, tagData); // Remplace le tag actuel par le tag modifié
                }

            })


        }



    });



}

export function tagify_instant_dropdown(){

    let i= $('input[name="tagify-instant-dropdown"]')
    let input=i.get(0)
    let whitelist=$('#tagify-instant-dropdown').attr('data-whitelist')




    let tagify=new Tagify(input, {
        whitelist: whitelist,

        dropdown: {
            position: 'input',
            maxItems: 50,
            classname: 'tags-look',
            enabled: 0, // always opens dropdown when input gets focus
            closeOnSelect: false, // Garde le dropdown ouvert après sélection


        }
    })



    i.show()

    // // Écoute de l'événement "input" pour effectuer une recherche
    // tagify.on('input', function (e) {
    //     const searchQuery = e.detail.value; // Récupère la valeur saisie
    //
    //     if (searchQuery.length > 0) {
    //         // Appeler le serveur pour récupérer les suggestions
    //         $.ajax({
    //             url: '/digikam/tags/search', // URL qui traite la recherche
    //             method: 'POST',
    //             data: { query: searchQuery }, // Envoyer la lettre ou la chaîne en cours de saisie
    //             success: function (response) {
    //                 // Supposons que le serveur retourne un tableau JSON de tags
    //                 tagify.whitelist = response.tags; // Met à jour la whitelist avec les suggestions
    //                 tagify.dropdown.show.call(tagify, searchQuery); // Force l'affichage du dropdown
    //             },
    //             error: function () {
    //                 console.error('Erreur lors de la récupération des tags');
    //             },
    //         });
    //     }
    // });

    // tagify.on('add', function (event) {
    //     // Récupérer les données du tag
    //     let tagData = event.detail.data;
    //
    //     // Modifier le tag pour supprimer tout après "/"
    //     if (tagData.value.includes('/')) {
    //         $.ajax({
    //             url: '/digikam/check_tag_for_creation',
    //             data: {
    //                 tagNom: tagData.value
    //             },
    //
    //             method: 'POST'
    //         }).done(function (response) {
    //             if (response['code'] === 200) {
    //                 tagData.value = response['tagNom'];
    //                 tagify.replaceTag(event.detail.tag, tagData); // Remplace le tag actuel par le tag modifié
    //             }
    //
    //         })
    //
    //
    //     }
    //
    //
    //
    // });



}
