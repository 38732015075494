// noinspection DuplicatedCode
import $ from "jquery";
import moment from "moment";
import {tagify_input} from "./OutilsTagify";
import '../../node_modules/jquery-confirm/dist/jquery-confirm.min.css';
import '../styles/css/confirm.css';
import '../../node_modules/jquery-confirm/dist/jquery-confirm.min.js';




//Cette fonction gère l'ouverture du modal de la classe "confirm-photo"
export function confirm_photo(url) {

    var jc = $.confirm({
        title: false,
        content: function () {
            var self = this;
            return $.ajax({
                url: url,
                data:{
                    path: url,
                },
                dataType: 'json',
                method: 'POST'
            }).done(function (response) {
                self.setContent(response['content']);
            }).fail(function () {
                self.setContent('Il y a eu un problème');
            });
        },
        columnClass: 'large',

        buttons: {
            cancel: {
                text: 'Annuler',
                action: function () {
                }
            }, formSubmit: {
                text: 'Sauvegarder',
                btnClass: 'btn-primary text-white',
                action: function () {

                    let form = $("[name='photo']");
                    $.ajax({
                        url: form.attr('action'),
                        data: form.serialize(),
                        method: 'POST'
                    }).done(function (response) {

                        if (response['code'] === 201) {
                            jc.close()
                            window.location.assign(response['url'])
                        } else if (response['code'] === 202) {
                            let photo=$('#bloc-reps').find('div[data-url="'+response['url']+'"]');
                            photo.find('img').attr('data-caption', response['caption']);
                            jc.close()
                        } else if (response['code'] === 203) {
                            if (response['caption'] !== ''){
                                let photos=response['photos']
                                photos.forEach(function (photo) {
                                    let pic=$('#bloc-reps').find('div[data-url="'+photo+'"]');
                                    pic.find('img').attr('data-caption', response['caption']);
                                })
                            }
                            jc.close()

                        } else if (response['code'] === 200) {
                            $(".jconfirm-content").html(response['content']);

                        }
                    })
                    return false;
                }
            },


        },
        onContentReady: function () {

            // on active la géolocalisation
            geolocalise()

            //pour gérer les tags dans le modal
            tagify_input()

            manage_changes()
            manage_caption_options()

        }


    });


}


// export function confirm_photo() {
//
//     $('.confirm-photo').on("click", function () {
//         let url = $(this).attr("data-url")
//
//         var jc = $.confirm({
//             title: false,
//             content: function () {
//                 var self = this;
//                 return $.ajax({
//                     url: url,
//                     dataType: 'json',
//                     method: 'POST'
//                 }).done(function (response) {
//                     self.setContent(response['content']);
//                 }).fail(function () {
//                     self.setContent('Il y a eu un problème');
//                 });
//             },
//             columnClass: 'large',
//
//             buttons: {
//                 cancel: {
//                     text: 'Annuler',
//                     action: function () {
//                     }
//                 }, formSubmit: {
//                     text: 'Sauvegarder',
//                     btnClass: 'btn-primary text-white',
//                     action: function () {
//
//                         let form = $("[name='photo']");
//                         $.ajax({
//                             url: form.attr('action'),
//                             data: form.serialize(),
//                             method: 'POST'
//                         }).done(function (response) {
//
//                             if (response['code'] === 201) {
//                                 jc.close()
//                                 window.location.assign(response['url'])
//                             } else if (response['code'] === 202) {
//                                 jc.close()
//                             } else if (response['code'] === 200) {
//                                 $(".jconfirm-content").html(response['content']);
//                             }
//                         })
//                         return false;
//                     }
//                 },
//
//
//             },
//             onContentReady: function () {
//                 confirm_delete()
//                 // on active la géolocalisation
//                 geolocalise()
//
//                 //pour dessiner les tags dans le modal
//                 setupTags();
//                 manage_changes()
//                 manage_caption_options()
//                 manage_fancybox(jc)
//
//
//             }
//
//
//         });
//     })
//
// }

// // Cette fonction permet de télécharger une picture (vignette) pour l'associer à une entité
export function managePicture(){
// Pour fonctionner cet outil doit respecter les points suivants :
//   - mettre dans la page edit de l'entité le loader de la picture (icône fa-light fa-upload). (partials/_upload_picture.html.twig)
//   - mettre dans le loader le nom de l'entité dans l'attribut data-entity
//   - mettre dans la page edit de l'entité une div "message-upload" pour gérer les messages d'erreur et de succès. Un exemple se trouve dans video_edit.html.twig
//   - créer dans le formType de l'entité un champ (non mappé) intitulé pictureName - Un exemple se trouve dans  VideoType.php
//   - mettre dans le controller de l'entité après la validation de l'edit ou de la création, le traitement de la picture. Un exemple se trouve dans la page VideoController.php
//   - la div qui montre l'image dans la page edit doit avoir pour id 'entity_picture'
//   - pour mémoire : les dimensions de la picture de l'entité doivent avoir été définies dans la variable .env
//   - pour mémoire : mettre dans le js qui gère l'entité la fonction managePicture !

    let input_picture=$('#input_picture');
    let trash=$('#trash')
    let entity = input_picture.attr('data-entity')
    if($('#entity_picture').hasClass('js-picture')){
        trash.show();
    }


    input_picture.on('click', function () {
        $("#message-upload").html('')
    }).on('change', function () {
        var fichierSelectionne = input_picture.get(0).files[0];
        var formData = new FormData();
        formData.append('file', fichierSelectionne)
        formData.append('entity', entity)

        // Si l'entité est un magasin, on indique que le fichier doit être de format png (type = 1) dans l'url
        let url='/admin/picture/upload/0';
        if (entity==='magasin'){
            url = '/admin/picture/upload/1'
        }

        $.ajax({
            url: url,
            type: 'POST',
            data: formData,
            processData: false,  // tell jQuery not to process the data
            contentType: false,  // tell jQuery not to set contentType
            success: function (response) {
                if (response['code'] === 205) {
                    $("#message-upload").removeClass('text-success').addClass('text-danger').html(response['error'])
                } else if (response['code'] === 200) {
                    $("#message-upload").removeClass('text-danger').addClass('text-success').html(response['ok'])

                    // on place la préparation de picture dans le champ picture
                    let a='#'+entity+'_pictureName'
                    if (entity==='participant'){
                        a='#share_user_pictureName'
                    }
                    $(a).val(response['file'])

                    // on la met dans le prototype

                    let img='<img src="'+response['file']+'" alt="picture"  class="rounded pointer" height="100%" data-fancybox="picture">'
                    $('#entity_picture').find('div').html(img)
                    trash.show()



                }
            }
        })
    })


    // on veut effacer la photo
    trash.on('click', function () {
        let margin='padding-top:'+trash.attr('data-margin');

        let b='<div class="fs-5 fst-italic text-center" style="'+margin+'">pas de photo</div>';
        // on place le mot delete dans le champ photoName
        let a='#'+entity+'_pictureName'

        if (entity==='auteur' || entity==='user'){
             // noinspection CheckImageSize
            b='<img src="/images/divers/anonyme.jpg" alt="photo"  class="js-modal rounded  pointer" height="80px" data-fancybox="picture">'
        }else if(entity==='album'){
            b='<img src="/images/divers/album_anonyme.jpg" alt="photo"  class="js-modal rounded  pointer" height="140px" data-fancybox="picture">'
        }else if(entity==='movie'){
            b='<img src="/images/divers/movie_anonyme.jpg" alt="photo"  class="js-modal rounded  pointer" height="140px" data-fancybox="picture">'
        }

        $(a).val('delete')
        // on efface la photo et on indique "aucune photo"
        $('#entity_picture').find('div').html(b)
        $("#message-upload").html('')

        trash.hide()

    })
}

// Cette fonction permet de télécharger une photo pour l'ajouter à la collection d'une entité
export function managePhotoCollection(){
// Pour fonctionner cet outil doit respecter les points suivants :
//   - mettre dans la page edit de l'entité le loader de la photo (icône fa-light fa-upload). Un exemple se trouve dans  journal_edit.html.twig
//   - mettre dans la page edit de l'entité une div "message-upload" pour gérer les messages d'erreur et de succès. Un exemple se trouve dans journal_edit.html.twig
//   - mettre dans le controller de l'entité après la validation de l'edit ou de la création, le traitement des photos du répertoire upload. Un exemple se trouve dans la page JournalController.php
//   - pour mémoire : mettre dans le js qui gère l'entité la fonction manageVignette !

    let input_photo_collection=$('#input_photo_collection');
    input_photo_collection.on('click', function () {
    }).on('change', function () {
        var fichierSelectionne = input_photo_collection.get(0).files[0];
        var entity = input_photo_collection.attr('data-entity')
        var id = input_photo_collection.attr('data-id')
        var formData = new FormData();
        formData.append('file', fichierSelectionne)
        formData.append('entity', entity)
        formData.append('id', id)

       let  url = '/admin/photo_collection/upload'

        $.ajax({
            url: url,
            type: 'POST',
            data: formData,
            processData: false,  // tell jQuery not to process the data
            contentType: false,  // tell jQuery not to set contentType
            success: function (response) {

                let url='/admin/'+response['entity']+'/edit/'+response['id']+'/'+response['code']
                window.location.assign(url)
            }
        })
    })
}
function manage_caption_options() {
    moment.locale('fr');
    let check_nom_jour = $('#check_nom_jour')
    let check_jour = $('#check_jour')
    let check_mois = $('#check_mois')
    let check_annee = $('#check_annee')
    let check_heure = $('#check_heure')
    let check_sans_date = $('#check_sans_date')
    let check_tags_lieux = $('#check_tags_lieux')
    let check_tags_personnes = $('#check_tags_personnes')
    let check_lieu = $('#check_lieu')
    let check_description = $('#check_description')

    $("#photo_description,#photo_lieu").on('change', function () {
        prepareCaption()
    })


    $("#comment").on('click', function () {

        check_tags_personnes.prop('checked', true)
        check_description.prop('checked', true)
        check_nom_jour.prop('checked', true)
        check_jour.prop('checked', true)
        check_mois.prop('checked', true)
        check_annee.prop('checked', true)
        if ($('#photo_lieu').val()!==''){
            check_lieu.prop('checked', true)
        }else{
            check_tags_lieux.prop('checked', true)
        }

        prepareCaption()


    })

    $("[id^='check_']").on('change', function () {

        // on s'assure de la cohérence des checks
        if ($(this).val() === 'sans_date' && $(this).is(':checked')) {
            // si l'option sans date est cochée, on décoche toutes les options date
            check_jour.prop('checked', false)
            check_nom_jour.prop('checked', false)
            check_mois.prop('checked', false)
            check_annee.prop('checked', false)
            check_heure.prop('checked', false)
        } else if ($(this).val() === 'tags_lieux' && $(this).is(':checked')) {
            // si l'option tags_lieux est cochée, on décoche l'option lieu
            check_lieu.prop('checked', false)
        } else if ($(this).val() === 'lieu' && $(this).is(':checked')) {
            // si l'option lieu est cochée, on décoche l'option tags_lieux
            check_tags_lieux.prop('checked', false)
        } else if ($(this).val() === 'nom_jour' && $(this).is(':checked')) {
            // si l'option nom_jour est cochée, on coche l'option jour, mois et annee
            check_jour.prop('checked', true)
            check_mois.prop('checked', true)
            check_annee.prop('checked', true)
        } else if ($(this).val() === 'jour' && !$(this).is(':checked')) {
            // si l'option jour n'est pas cochée, on décoche l'option nom jour et l'heure
            check_nom_jour.prop('checked', false)
            check_heure.prop('checked', false)
        } else if ($(this).val() === 'jour' && $(this).is(':checked')) {
            // si l'option jour est cochée, on coche l'option mois et annee
            check_mois.prop('checked', true)
            check_annee.prop('checked', true)
        } else if ($(this).val() === 'heure' && $(this).is(':checked')) {
            // si l'option heure est  cochée, on coche l'option jour, mois et année
            check_jour.prop('checked', true)
            check_mois.prop('checked', true)
            check_annee.prop('checked', true)
        } else if ($(this).val() === 'annee' && !$(this).is(':checked')) {
            // si l'option annee n'est  cochée, on décoche l'option nom_jour jour, mois et heure
            check_nom_jour.prop('checked', false)
            check_jour.prop('checked', false)
            check_mois.prop('checked', false)
            check_heure.prop('checked', false)
        } else if ($(this).val() === 'mois' && !$(this).is(':checked')) {
            // si l'option mois n'est pas cochée, on décoche l'option nom_jour jour,
            check_nom_jour.prop('checked', false)
            check_jour.prop('checked', false)
            check_heure.prop('checked', false)
        } else if ($(this).val() === 'mois' && $(this).is(':checked')) {
            // si l'option mois est cochée, on coche l'option annéee
            check_annee.prop('checked', true)
        }

        if (!check_jour.is(':checked') && !check_nom_jour.is(':checked') && !check_mois.is(':checked') && !check_annee.is(':checked') && !check_heure.is(':checked')) {
            // si aucune des options date n'est cochée, on coche sans date
            check_sans_date.prop('checked', true)
        } else {
            // si au moins une option date est cochée, on décoche l'option sans date
            check_sans_date.prop('checked', false)
        }
        prepareCaption()

    })

}

function prepareCaption(){
    // on va chercher les options
    let objets_options = $("[id^='check_']:checked")
    let options = []
    $.each(objets_options, function (key, value) {
        options.unshift(value.value) //
    })

    // on détermine les options qui vont être activées dans le caption
    let option_personnes = false;
    let option_lieux = false;
    let option_lieu = false;
    let option_description = false;
    let option_date = true;


    if (options.includes('description')) {
        option_description = true;
    }
    if (options.includes('tags_lieux')) {
        option_lieux = true;
    }
    if (options.includes('tags_personnes')) {
        option_personnes = true;
    }
    if (options.includes('lieu')) {
        option_lieu = true;
    }
    if (options.includes('sans_date')) {
        option_date = false;
    }


    // on compose la description
    let description = '';
    if (option_description) {
        description = $('#photo_description').val()
    }
    // on compose la date
    let date = '';
    if (option_date) {
        date = $('#photo_createdAt').val().replace('T', ' ')
        let option_nom_jour = false;
        let option_jour = false;
        let option_mois = false;
        let option_annee = false;
        let option_heure = false;
        if (options.includes('nom_jour')) {
            option_nom_jour = true;
        }
        if (options.includes('jour')) {
            option_jour = true;
        }
        if (options.includes('mois')) {
            option_mois = true;
        }
        if (options.includes('annee')) {
            option_annee = true;
        }
        if (options.includes('heure')) {
            option_heure = true;
        }

        if (option_nom_jour && option_jour && option_mois && option_annee && !option_heure) {
            date = moment(date).format('ddd Do MMM YYYY')
        } else if (option_nom_jour && option_jour && option_mois && option_annee && option_heure) {
            date = moment(date).format('ddd Do MMM YYYY à h:mm')
        } else if (!option_nom_jour && option_jour && option_mois && option_annee && !option_heure) {
            date = moment(date).format('Do MMM YYYY')
        } else if (!option_nom_jour && option_jour && option_mois && option_annee && option_heure) {
            date = moment(date).format('Do MMM YYYY à h:mm')
        } else if (!option_nom_jour && !option_jour && option_mois && option_annee && !option_heure) {
            date = moment(date).format('MMMM YYYY')
        } else if (!option_nom_jour && !option_jour && !option_mois && option_annee && !option_heure) {
            date = moment(date).format('YYYY')
        }
    }

    //on compose le lieu
    let lieu = '';
    if (option_lieu) {
        lieu = $('#photo_lieu').val()
    }

    // on initialise la variable "personnes"
    let personnes = '';

    if (option_lieux || option_personnes) {
        let objets_tags = $(".label-info")
        let tags = []
        $.each(objets_tags, function (key, value) {
            tags.unshift(value.outerText) //
        })

        let personnes_array = [];
        let lieux_array = [];
        if (tags.length !== 0) {
            $.ajax({
                url: '/admin/tag_type',
                method: 'POST',
                data: {
                    tags: tags,
                },
                dataType: 'json',
                success: function (response) {
                    if(option_personnes){
                        personnes_array = response['personnes'];
                        let nb = personnes_array.length
                        if (nb > 0) {
                            if (nb === 1) {
                                personnes = personnes_array[0]
                            } else if (nb === 2) {
                                personnes = personnes_array[0] + ' et ' + personnes_array[1]
                            } else {
                                $.each(personnes_array, function (key, value) {
                                    personnes = personnes + value
                                    if (key === nb - 2) {
                                        personnes = personnes + ' et '
                                    } else if (key !== nb - 1) {
                                        personnes = personnes+ ', '
                                    }
                                })
                            }
                        }
                    }

                    if(option_lieux){
                        lieux_array = response['lieux'];
                        let nb = lieux_array.length
                        if (nb > 0) {
                            if (nb === 1) {
                                lieu = lieux_array[0]
                            } else {
                                $.each(lieux_array, function (key, value) {
                                    lieu = lieu + value
                                    if (key !== nb - 1) {
                                        lieu = lieu + ', '
                                    }
                                })
                            }
                        }
                    }

                    makeCaption(personnes, description, lieu, date)
                }
            })
        } else {

            makeCaption(personnes, description, lieu, date)
        }

    } else {
        makeCaption(personnes, description, lieu, date)

    }
}


function makeCaption(personnes, description, lieu, date) {


    let caption='';
    if(personnes==='' && description!==''){
    // si les personnes ne sont pas indiquées et qu'il faut mettre une description, on met en majuscule la première lettre de la description
        description=description.charAt(0).toUpperCase()+description.slice(1)
    }


    if(personnes!=='' && description !=='' && lieu!=='' && date!==''){
        caption=personnes+': '+description+' ('+lieu+' - '+date+')'

    } else if(personnes!=='' && description !=='' && lieu!=='' && date===''){
        caption=personnes+': '+description+' ('+lieu+')'

    } else if(personnes!=='' && description !=='' && lieu==='' && date!==''){
        caption=personnes+': '+description+' ('+date+')'

    } else if(personnes!=='' && description !=='' && lieu==='' && date===''){
        caption=personnes+': '+description

    } else if(personnes!=='' && description ==='' && lieu!=='' && date!==''){
        caption=personnes+' ('+lieu+' - '+date+')'

    } else if(personnes!=='' && description ==='' && lieu!=='' && date===''){
        caption=personnes+' ('+lieu+')'

    } else if(personnes!=='' && description ==='' && lieu==='' && date!==''){
        caption=personnes+' ('+date+')'

    } else if(personnes!=='' && description ==='' && lieu==='' && date==='') {
        caption=personnes

    } else  if(personnes==='' && description !=='' && lieu!=='' && date!==''){
        caption=description+' ('+lieu+' - '+date+')'

    } else if(personnes==='' && description !=='' && lieu!=='' && date===''){
        caption=description+' ('+lieu+')'

    } else if(personnes==='' && description !=='' && lieu==='' && date!==''){
        caption=description+' ('+date+')'

    } else if(personnes==='' && description !=='' && lieu==='' && date===''){
        caption=description

    } else if(personnes==='' && description ==='' && lieu!=='' && date!==''){
        caption=lieu+' ('+date+')'

    } else if(personnes==='' && description ==='' && lieu!=='' && date===''){
        caption=lieu

    } else if(personnes==='' && description ==='' && lieu==='' && date!==''){
        caption=date

    }

    $('#photo_caption').val(caption )
    // console.log(caption)
}


function manage_changes() {

    // clic sur floppy disk caption
    $('#floppy-caption').on('click', function () {
        let valeur = $(this).attr('data-value')
        console.log(valeur)
        $('#photo_caption').val(valeur) // on remet la valeur enregistrée dans la bdd
        // prepareCaption() // on actualise le caption
    })

    // changement de la description
    $('#photo_description').on('change', function () {
        $(".js-descriptionActuel").show() //on fait apparaitre la valeur enregistrée dans la bdd
        prepareCaption() // on actualise le caption
    })
    // clic sur floppy disk description
    $('#floppy-description').on('click', function () {
        let valeur = $(this).attr('data-value')
        $('#photo_description').val(valeur) // on remet la valeur enregistrée dans la bdd
        $(".js-descriptionActuel").hide() // on cache le floppy disk
        prepareCaption() // on actualise le caption
    })

    // changement du lieu
    $('#photo_lieu').on('change', function () {
        $(".js-lieuActuel").show() //on fait apparaitre la valeur enregistrée dans la bdd
        prepareCaption() // on actualise le caption
    })

    // clic sur floppy disk lieu
    $('#floppy-lieu').on('click', function () {
        let valeur = $(this).attr('data-value')
        $('#photo_lieu').val(valeur) // on remet la valeur enregistrée dans la bdd
        $('#bloc-resultats').hide() // on cache éventuellement les résultats du geodecode
        $(".js-lieuActuel").hide() // on cache le floppy disk
        prepareCaption() // on actualise le caption

    })

    // changement dans l'input année
    $('#photo_annee').on('change', function () {
        let annee = $(this).val()
        let valeur = annee + '-01-01T12:00:00' //on fixe la date au 1er janvier de l'année
        $("#photo_createdAt").val(valeur) // on met cette date dans le champ createdAt
        $(this).val('') // on réinitialise le champ annéee
        $(".js-dateActuel").show() //on permet l'annulation en montrant le floopy disk
        prepareCaption() // on actualise le caption
    })

    // clic sur floppy disk date
    $('#floppy-date').on('click', function () {
        let jour = $(this).attr('data-jour') // on va chercher la date
        let heure = $(this).attr('data-heure') // on va chercher l'heure
        let valeur = jour + 'T' + heure // on compose la date et l'heure
        $("#photo_createdAt").val(valeur) // on remet la valeur dans le champ createdAt
        $(".js-dateActuel").hide() // on cache le floppy
        prepareCaption() // on actualise le caption
    })

    $('#photo_createdAt').on('change', function () {
        $(".js-dateActuel").show() //on fait apparaitre la valeur enregistrée dans la bdd
        prepareCaption() // on actualise le caption
    })
}


function geolocalise() {
    $('#findLieu').on('click', function () {
        // on va chercher la latitude, la longitude, le lieu actuel et l'id de la photo séléctionnée
        let coordonnees_gps=$(this).attr('data-gps');

        // on envoie l'ajax pour récupérer le lieu proposé
        let url = '/admin/photo/suggestlieu'
        $.ajax({
            url: url,
            method: 'POST',
            dataType: 'json',
            data: {
                coordonnees_gps: coordonnees_gps,
            },
            success: function (response) {

                insertData(response['results'])
                manageData()
                $('.js-lieuActuel').show()

            }
        });


    })

}

function insertData($data) {

    $('.js-lieuActuel').show() // on fait apparaitre le lieu Actuel et la possibilité d'annultation
    $('#bloc-resultats').show() // on fait apparaitre le bloc résultats

    // on efface les résultats précédents
    $('#resultats').html('');

    // on constitue le bloc choix des réponses  en initialisant le résultat proposé aux trois premiers choix
    let propose = '';
    $.each($data, function (index, value) {

        let check = ''
        if (index === "0") {
            propose = value
            check = 'checked'
        }
        if (index === "1") {
            propose = propose + ', ' + value
            check = 'checked'
        }
        if (index === "2") {
            propose = propose + ', ' + value
            check = 'checked'
        }

        let a = '<div class="form-check">'
        a += '<input class="possible form-check-input" type="checkbox" value="' + value + '" id="btn' + index + '" ' + check + '>'
        a += '<label class="form-check-label" for="btn' + index + '">'
        a += value
        a += '</label></div>'

        // on ajoute au fur et à mesure les choix au bloc résultats
        $("#resultats").append(a)
    })

    // on propose une réponse
    $('#photo_lieu').val(propose)
}

function manageData() {


    $('.form-check-input').on('change', function () {
        // on sélectionne les items cochés
        let items = $('.possible:checked')
        let propose = ''
        $.each(items, function (key, value) {
            if (key === 0) {
                propose = propose + value['value']
            } else {
                propose = propose + ', ' + value['value']
            }
        })
        // on les affiche dans le lieu
        $('#photo_lieu').val(propose)
    })

}

function manageSubmit(){
    let myModal = new Modal($('#modal'));
    $('#submit-form').on('click', function (e) {
        let form = $('form');
        let prog_position=$('#prog-position')
        let position=prog_position.attr('data-position')
        let prog=prog_position.attr('data-prog')
        e.preventDefault();
        $.ajax({
            url: form.attr('action'),
            data: form.serialize(),position,prog,
            method: 'POST'
        }).done(function (response) {

            if(response['code'] === 207){
                myModal.hide()
            }else if(response['code'] === 208){
                myModal.hide()
                updatePageResultsAfterAction(response['prog'],response['position'])

                // $('#'+response['position']).find('img').addClass('opacity-25').removeClass('pointer')

            }else if(response['code'] === 201){
                window.location.assign(response['referer'])
            }else{
                $(".modal-content").html(response['modal'])
                manageSubmit()
            }

        })
    })


}


function manage_fancybox(jc){
   $("[data-fancybox]").on('click', function (e) {
      jc.close()

   })

}
